<template>
  <div>Contact</div>
  <!-- <SectionSeparator></SectionSeparator> -->
</template>

<script>
// import SectionSeparator from "./Styles/SectionSeparator.vue";

export default {
  name: 'ContactComponent',
  components: {
    // SectionSeparator,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
