<template>
  <div>About</div>
</template>

<script>
export default {
  name: 'AboutComponent',
  components: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
